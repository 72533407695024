import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout.js';
import BackgroundImage from 'gatsby-background-image';
import { graphql } from 'gatsby';
import get from 'lodash/get';

import styles from './service.module.css';

class Service extends React.Component {
  render() {
    const page = get(this, 'props.data.contentfulSeite');
    const sponsors = get(this, 'props.data.allContentfulSponsor.edges');
    
    return (
      <Layout location={this.props.location} sponsors={sponsors}>
        <SEO title={page.title} />
        <BackgroundImage backgroundColor="#0e3920" className="header" fluid={page.heroImage.fluid}>
          <h1>Service</h1>
        </BackgroundImage>
        <div className="container">
          {page.text &&
            <article  
              className="markdown"
              dangerouslySetInnerHTML={{
                __html: page.text.childMarkdownRemark.html,
              }} 
            />
          }
          <div className={styles.downloads}>
            <h2>Downloads</h2>
            {page.downloads.map((download, index) => {
              const fileSize = download.file.details.size / 1000000;
              return (
                <div key={index} className={styles.download}>
                  <div className={styles.downloadInfo}>
                    <span className={styles.downloadTitle}>{download.title}</span>
                    <span className={styles.downloadSize}>{`${fileSize.toFixed(1)} MB`}</span>
                  </div>
                  <a href={download.file.url} download target="_blank">
                    Datei laden
                  </a>
                </div>
              )
            })}
          </div>
        </div>
      </Layout>
    )
  }
}

export default Service;

export const pageQuery = graphql`
  query ServiceQuery {
    allContentfulSponsor(filter: { id: { ne: "10296087-115a-526e-9f65-04404f89693a" }}) {
      edges {
        node {
          name
          website
          logo {
            fixed(width: 250, quality: 100) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
    contentfulSeite(slug: { eq: "service" }) {
      title
      heroImage {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      text {
        childMarkdownRemark {
          html
        }
      }
      downloads {
        title
        file {
          fileName
          url
          details {
            size
          }
        }
      }
    }
  }
`;
